<template>  
  <OModal ref="OModalRef" @hidden="" :modalOptions="{focus: false}">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header d-flex flex-column align-items-start gap-2">
              <div class="gap-2 d-flex gap-2 w-100">
                <input type="text" v-model="sprintRow.Name" class="form-control fw-bold fs-5 border-0 ps-0">
                <button type="button" class="btn-close align-self-start" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
            </div>

            <div class="modal-body d-flex flex-column gap-2">
                <div>
                  <label class="fw-bold text-muted pb-1">Start date</label>
                  <ODatePicker @click.prevent.stop="" inputClassName="form-control form-item " v-model="sprintRow.StartDate" format="Short Date"></ODatePicker>
                </div>
                <div>
                  <label class="fw-bold text-muted pb-1">End date</label>
                  <ODatePicker inputClassName="form-control form-item" v-model="sprintRow.EndDate" format="Short Date"></ODatePicker>
                </div>
                <div>
                  <label for="sprint-goal" class="fw-bold small text-muted pb-1">Sprint goal</label>
                  <textarea id="sprint-goal" v-model="sprintRow.Goal" rows="3" class="form-control form-item"></textarea>
                </div>
                <div class="row">
                  <div class="col">
                    <label class="form-check-label pe-2" for="copy-open-issues">Copy open issues</label>
                    <input class="form-check-input" type="checkbox" v-model="sprintRow.shouldCopyOpenIssues" id="copy-open-issues" checked>
                  </div>
                  <div class="col">
                    <label class="form-check-label pe-2" for="set-current-sprint">Set as current sprint</label>
                    <input class="form-check-input" type="checkbox" v-model="sprintRow.shouldSetAsCurrentSprint" id="set-current-sprint" checked>
                  </div>
                  <div class="col">
                    <label class="form-check-label pe-2" for="close-current-sprint">Close current sprint</label>
                    <input class="form-check-input" type="checkbox" v-model="sprintRow.shouldClosePreviousSprint" id="close-current-sprint">
                  </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="d-flex flex-row gap-2">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="createNewSprint()">Create Sprint</button>
                </div>
            </div>
        </div>
    </div>
  </OModal>
</template>

<script setup>
import { ref, reactive, watch } from 'vue'
import OModal from 'o365.vue.components.Modal.vue';
import Procedure from 'o365.modules.Procedure.ts';
import { getOrCreateDataObject } from 'o365.vue.ts'

const props = defineProps({
  boardID: {
    type: Number,
    required: true
  },
  sprintID: {
    type: Number,
    required: true
  }
});

const dsSprintsCreated = getOrCreateDataObject({
    id: 'dsSprintsCreated',
    viewName: 'atbv_Kanban_Sprints',
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [
            {name: "ID", type: "number" },
            {name: "Name", type: "string" },
            {name: "Board_ID", type: "number" },
        ]
})

const OModalRef = ref(null);

/*
const sprintRow = computed(() => {return { 
  Name: "Sprint Name", 
  Goal: "", 
  StartDate: null, 
  EndDate: null, 
  Board_ID: props.boardID, 
  CopyFromSprint_ID: props.sprintID,
  shouldCopyOpenIssues: true, 
  shouldSetAsCurrentSprint: true,
  shouldClosePreviousSprint: false
}});
*/

const sprintRow = reactive({ 
  Name: "Sprint Name", 
  Goal: "", 
  StartDate: null, 
  EndDate: null, 
  Board_ID: props.boardID, 
  CopyFromSprint_ID: props.sprintID,
  shouldCopyOpenIssues: true, 
  shouldSetAsCurrentSprint: true,
  shouldClosePreviousSprint: false
});

watch(() => props.boardID, (newBoardID) => {
  sprintRow.Board_ID = newBoardID;
});
watch(() => props.sprintID, (copyFromSprintID) => {
  sprintRow.CopyFromSprint_ID = copyFromSprintID;
});

const procCreateNewSprint = new Procedure({ id:"procCreateNewSprint", procedureName:"astp_Kanban_CreateNewSprint" });

async function createNewSprint(){
  await procCreateNewSprint.execute(sprintRow);
  // Finding the created SprintID by trusting unique constraint on Board_ID and Name
  dsSprintsCreated.recordSource.whereClause = `Board_ID = '${sprintRow.Board_ID}'`;
  dsSprintsCreated.load().then(() => {
    window.open(`/kanban-boards/kanban-board?SprintID=${dsSprintsCreated.data.find(s => s.item.Name === sprintRow.Name).item.ID}`);
    emit('created');
  })
}

const emit = defineEmits(['created']);
defineExpose({OModalRef})
</script>

<style>
[data-bs-theme="dark"] {
  --form-item-background-color: #252a2e;
}
[data-bs-theme="light"] {
   --form-item-background-color: #f8f8f8;
}

.form-item {
  background-color: var(--form-item-background-color);
}

#sprint-goal { 
  resize: none;
}
</style>